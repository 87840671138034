import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'

import Menu from '../components/menu'
import BookTable from '../components/BookTable'
import Layout from '../components/layout'
import SEO from '../components/seo'
import useWindowSize from '../components/useWindowSize';

const Yomiuri = ({data}) => {
    const books = _.groupBy(data.allMongodbIndexPrizeItems.edges.map(book => book.node), 'year')
    const {width} = useWindowSize();
    const sp = width < 640;

    return (
        <Layout>
            <SEO title='読売文学賞の受賞作品一覧' description='読売文学賞の受賞作品の一覧を年代順にまとめたページです'/>
            <div className='ui relaxed centered grid'>
                <Menu active='yomiuri'/>
                <div className={`${sp ? 'sixteen' : 'eight'} wide column`}>
                    <BookTable books={books}　prize_name='読売文学賞'/>
                </div>
            </div>
        </Layout>
    )
}

export default Yomiuri

export const query = graphql`
{
    allMongodbIndexPrizeItems (filter: {prize_name: {eq: "yomiuri"}}) {
        edges {
            node {
                author
                book
                year
                description
                image_path
                mongodb_id
                prize_name
            }
        }
    }
}
`
